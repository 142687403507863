<template>
  <div class="mb-100">
    <div class="divider-full"></div>
    <!-- <div class="container-fluid section-head section-tour">
      <div class="container-standar container-res-1440 sub-tour">
        <span class="pt-6 pb-6 text-white font-weight-6 font-24 d-inline-block text-with">Umroh</span>
      </div>
    </div> -->

    <!-- SEARCH UMROH START -->
    <div class="mt-6 container-standar">
      <div class="row">
        <div class="col-lg-9">
          <div class="row mb-10">
            <div class="col-lg-4">
              <label
                for="destination"
                class="text-primary font-12 font-weight-6"
              >Pilih Bulan Keberangkatan</label>
              <v-select class="date-icon" :options="month" name="month" v-validate="'required'"  v-model="montModel" placeholder="Select Departure"></v-select>
               <span class="eror text-danger font-10" >{{errors.first('month')}}</span>
              <span></span>
            </div>
            <div class="col-lg-4">
              <label for="destination" class="text-primary font-12 font-weight-6">Keberangkatan Dari</label>
              <v-select class="user-icon"               
                name="fromDepart"  
                :options="sort"  
                label="text"   
                v-model="fromDepart"
                placeholder="Select ..."></v-select>
               <span class="eror text-danger font-10" >{{errors.first('fromDepart')}}</span>
            </div>
            <div class="col-lg-4">
              <label for="destination" class="text-primary font-12 font-weight-6">Lama Perjalanan</label>
              <v-select class="date-icon" name="duration" v-model="durationModel" v-validate="'required'" :options="duration" placeholder="Select Duration"></v-select>
               <span class="eror text-danger font-10" >{{errors.first('duration')}}</span>
            </div>
          </div>
           
        </div>
        <div class="col-lg-3">
          <div class="pos-relative tr-all  ">
            <a
              href="#"
              @click.prevent="submit()"
              class="btn bg-warning advance-search text-white font-weight-6 pt-2 mt-4 pb-2 width-100 font-16"
            >
              <span>
                Cari Sekarang
                <i class="fa fa-search"></i>
              </span>
            </a>

            <!-- <div class="advance-search mb-10 width-100">
              <label for="anyDes" class="text-primary float-left font-16 font-weight-6">Return Date</label>
              <div class="ml-3 mt-1 float-left">
                <switches v-model="enabled" theme="bootstrap" color="success"></switches>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- SEARCH UMROH END -->
    <div class="mt-6 container-standar">
      <div class="row">
        <div class="col-md-6">
          <h1 class="font-weight-6 text-primary">
            {{resultMsg}}
          </h1>
        </div>
      </div>
    </div>
    <div class="container-standar mt-3">
      <div class="row">
        <div class="col-md-3 w-res-tab-small-flex-50 mt-2 mb-10 w-res-flex-100" :key="index" v-for="(items, index) in dataTour">
          <div class="container-maskapai-umroh">
            <img v-bind:src="items.airline[0].logo" alt/>
          </div>

          <div class="card shadow h-100 card-for-umroh cursor-pointer" @click="changePage(items)">
            <div class="tour-card2">
                <template v-if="items.images == 0">
                  <img src="@/assets/images/background/tour-1.jpg" :alt="items.slug">
                </template>
                <template v-else >
                   <div>
                    <img v-bind:src="chesen(items)" style="width:100%" :alt="items.slug" />
                  </div>
                </template>  
              <!--<span class="labelTour font-16 font-weight-6 text-white">{{ items.day+' Hari' }}</span>-->
            </div>
            <div class="title pad-10">
              <span class="d-inline-block font-13 text-primary font-weight-7">{{ items.nama }}</span>
              <div class="content mt-2">
                <p class="umroh_hotel">
                  <span class="d-inline-block width-100 float-right text-left font-weight-7 text-warning font-16 price-umroh">
                  {{getDate(items.date_depart)}} - {{ items.day+' Hari' }}
                  </span>
                </p>
                <p class="umroh_hotel" v-for="(hotel, j) in items.hotel" :key="j">
                  {{hotel.name}}
                  <span>
                    <i v-for="n in parseInt(hotel.star)" :key="n" class="fa fa-star"></i>
                  </span>
                </p>
              </div>
              <span class="d-inline-block width-50 float-left text-left mt-6 font-weight-7 text-warning font-16 price-umroh"
              v-bind:class="{'text-decoration': items.diskon != 0}">
                IDR {{ items.amount | numeralFormat('0,0[.]00') }}</span>
              <span class="d-inline-block width-50 float-right text-right mt-6 font-weight-7 text-warning font-14 price-umroh" v-if="items.diskon != 0">
                IDR {{ items.amount - items.diskon | numeralFormat('0,0[.]00') }}</span>
            </div>
          </div>
        </div>        
      </div>
      <template v-if="numberCount <= totalCount">
         <div class="col-md-12 text-center justify-center mt-10" >
          <span @click.prevent="countMethod()" class=" d-block width-20 w-res-100 w-res-tab-small-80   margin-auto text-center border-radius-100 bg-warning cursor-pointer text-white  pad-10 font-18 font-weight-6">Tampilkan Lagi</span>
        </div>
      </template>
      
    </div>
  </div>
</template>
<script>
import * as moment from 'moment';
export default {
  data() {
    return {
      dataTour:[],
      dataImages:{},
      dataPagging:[],
      numberCount: 12,
      showCount:null,
      totalCount:null,
      parse:null,
      resultMsg:'DAFTAR SEMUA PAKET UMROH',
      enabled: true,
      showDrop: false,
      dateplan: null,
      montModel:'',
      durationModel:null,
      fromDepart:null,
      month: ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"],
      sort: [
        { "id": 0, text: 'Cabang Jakarta' },
        { "id": 1, text: 'Cabang Makassar'},
        { "id": 2, text: 'Cabang Maros' },
        { "id": 3, text: 'Cabang Bandung'}, 
        { "id": 4, text: 'Cabang Pekanbaru' },
        { "id": 5, text: 'Cabang Banten'},
        { "id": 6, text: 'Cabang Jepara' },
        { "id": 7, text: 'Cabang Palembang'},
        { "id": 8, text: 'Cabang Lampung' },
        { "id": 9, text: 'Cabang Polewali Mandar'},
      ],
      duration: ["9 Hari", "10 Hari", "11 Hari", "12 Hari", "13 Hari", "14 Hari", "15 Hari", "16 Hari","17 Hari"],
      maxBudget: null,
      failedMessage : false,
      daterange: null,
      date: new Date(),
      config: {
        mode: "single",
        minDate: "today",
        dateFormat: "d-M-Y"
      }
    };
  },
  mounted() {
    this.$store.commit("SET_BG", false);
    localStorage.removeItem("response-date-depart");
    this.dataparse =  JSON.parse(localStorage.getItem('response-umroh'))
    if (this.dataparse == null) {
      this.getData()
    } else {      
      if (this.dataparse.rest_no != 0) {
        this.totalCount = 0
        this.resultMsg = 'Hasil pencarian tidak ditemukan.'      
      } else {
        this.parseMethod()
        this.totalCount = this.dataparse.data.length
        this.resultMsg = 'HASIL PENCARIAN UMROH'      
      } 
    }  
  },
  methods: {
    getData() {
      let param = new FormData();
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "umroh");
      param.append("keyword", ''); 
      param.append("call", "list_umroh"); 
      param.append("page", 1); 
      param.append("per_page", 1000); 
      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain"
          })
        })
        .then(response => {
          let res = response.data;
          this.dataparse = res
          this.parseMethod()
          this.totalCount = this.dataparse.data.length
        })
        .catch(error => {
          error;
        });
    },
    submit(){ 
      let param = new FormData();
      let dm = moment().month(this.montModel).format('MM');
      let fdepart = (this.fromDepart != null) ? this.fromDepart.text : '';
      console.log(this.durationModel)
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "umroh");
      param.append("keyword", ''); 
      param.append("call", "list_umroh"); 
      param.append("month_depart", (this.montModel == '') ? '' : dm); 
      param.append("from_depart", fdepart); 
      param.append("day", (this.durationModel == null) ? '' : this.durationModel.substring(0, 2) )
      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain"
          })
        })
        .then(response => {
          let res = response.data;
          if (res.rest_no != 0) {
            this.dataTour = []
            this.totalCount = 0
            this.resultMsg = 'Hasil pencarian tidak ditemukan.'
          } else {              
            this.dataparse = res
            this.parseMethod()
            this.totalCount = this.dataparse.data.length
            this.resultMsg = 'Hasil pencarian umroh.'
          }
        })
        .catch(error => {
          error;
      });
    },
    parseMethod(){
        this.dataTour = this.dataparse.data.slice(0, this.numberCount)
    },
    countMethod(){
      this.numberCount = this.numberCount + 12
      this.parseMethod()
       
    },
    chesen(val){
      return val.image
    },
    getDate(val){
      moment.locale('ID'); 
      return moment(val).format('LL')
    },
    changePage(NewVal){
      localStorage.setItem("response-date-depart", JSON.stringify(NewVal.date_depart));
      this.$router.push('/paket-umroh/detail/'+NewVal.slug+'/'+NewVal.date_depart)
    }
  },
};
</script>